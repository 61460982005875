export default {
  "row": "cuI",
  "disabled": "cut",
  "cell": "cuM body-2",
  "cell-link": "cuP",
  "cell-content": "cul",
  "item-truncate-text": "cuX",
  "item-name-cell": "cuC",
  "item-account-cell": "cuk",
  "icon": "cuJ",
  "icon-download": "cus"
};
