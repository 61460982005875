export default {
  "team-card": "qES",
  "card-link": "qEc",
  "shadow": "qEq",
  "history-button": "qEZ",
  "cards-button": "qER",
  "menu-item": "qEQ",
  "subtitle": "qEe",
  "delete-team": "qEB"
};
