export default {
  "supplier-selector-wrapper": "qqt",
  "select-supplier": "qqM",
  "dropdown": "qqP",
  "iban": "qql",
  "card": "qqX body-2",
  "card-content": "qqC",
  "add-btn": "qqk btn btn--square btn--icon-only",
  "archived-card": "qqJ",
  "title": "qqs body-1",
  "archived-title": "qqF",
  "edit-supplier-tooltip": "qqg",
  "add-supplier": "qqD btn btn--tertiary",
  "error": "qqV caption",
  "form-title": "qqL title2",
  "dropdown-menu": "qqT",
  "supplier-invoice-detail-menu": "qqz",
  "item-divider": "qqa",
  "menu-item": "qqH body-2 dropdown-overlay",
  "disabled": "qqO",
  "iban-error": "qqm",
  "iban-disclaimer": "qqp"
};
