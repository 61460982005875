export default {
  "custom-export-loading": "qdn",
  "title": "qdi",
  "description": "qdA",
  "section": "qdY",
  "placeholder-avatar": "qdh",
  "_with-avatar": "qdf",
  "_with-list": "qdK",
  "list-item": "qdG",
  "radio": "qdr",
  "header": "qdb",
  "header-line": "qdy"
};
