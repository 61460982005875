export default {
  "beneficiary-details": "qJV",
  "beneficiary-account-types": "qJL",
  "divider": "qJT",
  "label": "qJz",
  "labels": "qJa",
  "error": "qJH",
  "link": "qJO caption",
  "sticky-panel": "qJm"
};
