export default {
  "filters-checkbox": "qII mb-8",
  "csv-options-button": "qIt",
  "active": "qIM",
  "options-format-settings-wrapper": "qIP",
  "checkbox": "qIl",
  "options-format-settings": "qIX",
  "hidden": "qIC",
  "visible": "qIk",
  "disabled": "qIJ",
  "buttons": "qIs"
};
