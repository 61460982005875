export default {
  "header-cell": "qCj",
  "empty": "qCW",
  "header-type": "qCu",
  "header-requester": "qkS",
  "header-amount": "qkc",
  "header-approval": "qkq",
  "header-content": "qkZ caption-bold",
  "active": "qkR",
  "row-compact": "qkQ",
  "row-sidebar": "qke",
  "hidden": "qkB",
  "animated": "qkE",
  "fadein-item": "qkd",
  "animated-cell": "qkI"
};
