export default {
  "wrapper": "qlx",
  "title": "qlw",
  "activity-tag": "qlN",
  "select": "qlv",
  "tooltip": "qlo",
  "date-picker": "qln",
  "internal-notes": "qli",
  "scheduled": "qlA",
  "payroll-selector-wrapper": "qlY"
};
