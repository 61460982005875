export default {
  "cell": "qRD",
  "supplier-container": "qRV",
  "cell-label-container": "qRL",
  "cell-label": "qRT",
  "overflow": "qRz",
  "sub-label": "qRa body-2",
  "overdue": "qRH",
  "file-name-warning": "qRO",
  "missing": "qRm",
  "pending-icon": "qRp",
  "icon": "qRx",
  "flex-label": "qRw",
  "with-separator": "qRN",
  "self-invoice-icon": "qRv",
  "tooltip-wrapper": "qRo"
};
