export default {
  "header": "qSR",
  "subtitle": "qSQ body-2",
  "date": "qSe",
  "subtitle-error": "qSB",
  "card": "qSE",
  "card-header": "qSd",
  "line-placeholder": "qSI",
  "post-closing-list": "qSt",
  "post-closing-container": "qSM",
  "post-closing-list-element": "qSP",
  "free-trial-disclaimer": "qSl",
  "description": "qSX body-2",
  "mw-100": "qSC",
  "addon-overview": "qSk",
  "addons-container": "qSJ"
};
