/* import __COLOCATED_TEMPLATE__ from './supplier-information.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { IT_FISCAL_REGIME_DESCRIPTION } from 'qonto/constants/self-billing';

interface SelfBillingFormSupplierInformationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class SelfBillingFormSupplierInformationComponent extends Component<SelfBillingFormSupplierInformationSignature> {
  taxRegime = IT_FISCAL_REGIME_DESCRIPTION;

  updateSupplier(field: string, value: unknown) {
    // @ts-expect-error
    this.args.invoice.set('supplierSnapshot', {
      // @ts-expect-error
      ...(this.args.invoice.get('supplierSnapshot') ?? {}),
      [field]: value,
    });
  }

  @action
  // @ts-expect-error
  updateName(name) {
    this.updateSupplier('name', name);
    // @ts-expect-error
    this.args.invoice.errors.remove('supplierSnapshotName');
  }

  @action
  // @ts-expect-error
  updateZipCode(zipCode) {
    this.updateSupplier('zipCode', zipCode);
    // @ts-expect-error
    this.args.invoice.errors.remove('supplierSnapshotZipCode');
  }

  @action
  // @ts-expect-error
  updateAddress(address) {
    this.updateSupplier('address', address);
    // @ts-expect-error
    this.args.invoice.errors.remove('supplierSnapshotAddress');
  }

  @action
  // @ts-expect-error
  updateCity(city) {
    this.updateSupplier('city', city);
    // @ts-expect-error
    this.args.invoice.errors.remove('supplierSnapshotCity');
  }

  @action
  // @ts-expect-error
  updateCountry(country) {
    this.updateSupplier('countryCode', country);
    // @ts-expect-error
    this.args.invoice.errors.remove('supplierSnapshotCountryCode');
  }

  @action
  // @ts-expect-error
  updateVatNumber(vatNumber) {
    this.updateSupplier('vatNumber', vatNumber);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::Form::SupplierInformation': typeof SelfBillingFormSupplierInformationComponent;
  }
}
