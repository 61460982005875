export default {
  "label-select": "cWK",
  "puce": "cWG",
  "puce-wrapper": "cWr",
  "puce-container": "cWb",
  "readonly": "cWy",
  "label-display": "cWU",
  "label-list-name": "cWj body-1",
  "label-list-value": "cWW body-2"
};
