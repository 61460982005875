export default {
  "cell": "qRn",
  "cell-label-container": "qRi",
  "cell-label": "qRA",
  "overflow": "qRY",
  "missing": "qRh",
  "credit-note-amount": "qRf",
  "payable-amount": "qRK",
  "amount": "qRG"
};
