export default {
  "transaction": "qIz",
  "transaction-details": "qIa",
  "transaction-details-amount": "qIH body-1",
  "credit": "qIO",
  "negative-amount": "qIm",
  "transaction-details-description": "qIp body-2",
  "transaction-remove-cta": "qIx",
  "avatar": "qIw mr-16",
  "spinner": "qIN"
};
