import { template as template_990608dc25db483e81ba540389264c9f } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FilterSelect from '@qonto/ui-kit/components/filter-select';
import { t } from 'ember-intl';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import { equal, reads } from 'macro-decorators';
import ReactBridge from 'qonto/components/react-bridge';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import { DUE_DATE_FILTER, FILTERS, INVOICE_STATUSES, PAYMENT_DATE_FILTER_OPTIONS, TABS } from 'qonto/constants/supplier-invoice';
import { MultiSelectFilter } from 'qonto/react/components/supplier-invoices/multi-select-filter';
import { ToggleFilter } from 'qonto/react/components/supplier-invoices/toggle-filter';
import SuppliersFilter from '../suppliers/filter';
import styles from './filters.strict-module.css';
const FILTER_TAB_MAP = (hasApprovalWorkflow, canUpdateSupplierInvoices)=>{
    let toPayFilters = [];
    if (hasApprovalWorkflow && canUpdateSupplierInvoices) {
        toPayFilters.push(FILTERS.ASSIGNED_TO_YOU);
    }
    toPayFilters.push(FILTERS.DUE_DATE);
    return {
        [TABS.TASKS]: [],
        [TABS.ALL_INVOICES]: [
            FILTERS.SUPPLIERS,
            FILTERS.MULTI_STATUS,
            FILTERS.TO_REVIEW
        ],
        [TABS.INBOX]: [
            FILTERS.SUPPLIERS,
            FILTERS.DUE_DATE,
            FILTERS.DUPLICATES,
            FILTERS.NON_FINANCIAL_DOCUMENT,
            FILTERS.MISSING_DATA
        ],
        [TABS.TO_PAY]: toPayFilters,
        [TABS.TO_APPROVE]: hasApprovalWorkflow && canUpdateSupplierInvoices ? [
            FILTERS.ASSIGNED_TO_YOU,
            FILTERS.DUE_DATE
        ] : [
            FILTERS.DUE_DATE
        ],
        [TABS.SCHEDULED]: [
            FILTERS.SUPPLIERS
        ],
        [TABS.COMPLETED]: [
            FILTERS.SUPPLIERS,
            FILTERS.ARCHIVED_PAID_STATUS,
            FILTERS.PAYMENT_DATE,
            FILTERS.NO_MATCHED_TRANSACTIONS
        ]
    };
};
const TO_REVIEW_FILTER_OPTIONS = {
    DUE_AND_OVERDUE: 'due_and_overdue',
    DUPLICATES: 'duplicates',
    NON_FINANCIAL_DOCUMENT: 'non_financial_documents',
    MISSING_DATA: 'missing_data',
    NO_MATCHED_TRANSACTIONS: 'no_matched_transactions',
    ASSIGNED_TO_YOU: 'assigned_to_you'
};
const INBOX_FILTERS_TRACKING_EVENTS = {
    [TO_REVIEW_FILTER_OPTIONS.DUPLICATES]: 'supplier-invoices_duplicate-button_clicked',
    [TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT]: 'supplier-invoices_unrecognized-button_clicked',
    [TO_REVIEW_FILTER_OPTIONS.MISSING_DATA]: 'supplier-invoice_missing-data-button_clicked'
};
export default class SupplierInvoicesFilters extends Component {
    @service
    intl;
    @service
    abilities;
    @service
    segment;
    @service
    subscriptionManager;
    @service
    supplierInvoicesInsights;
    @equal('args.tab', TABS.ALL_INVOICES)
    isAllInvoicesTab;
    @reads('supplierInvoicesInsights.currentInsights')
    insights;
    get filters() {
        return (FILTER_TAB_MAP(this.abilities.can('see state approval-workflow'), this.abilities.can('update supplier-invoice'))[this.args.tab] || []);
    }
    get showSuppliersFilter() {
        return this.abilities.can('view supplier') && this.args.suppliers.length > 0;
    }
    get canUpdateSupplierInvoices() {
        return this.abilities.can('update supplierInvoice');
    }
    get completedStatusOptions() {
        return [
            {
                code: INVOICE_STATUSES.paid,
                value: this.intl.t('supplier-invoices.preview.status.paid')
            },
            {
                code: INVOICE_STATUSES.archived,
                value: this.intl.t('supplier-invoices.preview.status.archived')
            },
            {
                code: INVOICE_STATUSES.rejected,
                value: this.intl.t('supplier-invoices.filters.status.value.rejected')
            }
        ];
    }
    get selectedPaymentDate() {
        return this.paymentDateFilterOptions.find(({ code })=>code === this.args.paymentDate);
    }
    get paymentDateFilterOptions() {
        return [
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.TODAY,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.today')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.YESTERDAY,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.yesterday')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.THIS_WEEK,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.this-week')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.PAST_WEEK,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.last-week')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.THIS_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.this-month')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.PAST_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.last-month')
            }
        ];
    }
    get selectedStatus() {
        return this.completedStatusOptions.find((opt)=>opt.code === this.args.status);
    }
    get multiStatusFilterOptions() {
        return [
            {
                value: INVOICE_STATUSES.toReview,
                text: this.intl.t('supplier-invoices.filters.status.value.imported')
            },
            {
                value: INVOICE_STATUSES.toApprove,
                text: this.intl.t('supplier-invoices.filters.status.value.to-approve')
            },
            {
                value: INVOICE_STATUSES.awaitingPayment,
                text: this.intl.t('supplier-invoices.filters.status.value.to-pay')
            },
            ...(this.subscriptionManager?.hasFeature('supplier_invoices_processing_invoices') ? [
                {
                    value: INVOICE_STATUSES.scheduled,
                    text: this.intl.t('supplier-invoices.filters.status.value.scheduled')
                }
            ] : []),
            {
                value: INVOICE_STATUSES.paid,
                text: this.intl.t('supplier-invoices.filters.status.value.paid')
            },
            {
                value: INVOICE_STATUSES.archived,
                text: this.intl.t('supplier-invoices.filters.status.value.archived')
            },
            {
                value: INVOICE_STATUSES.rejected,
                text: this.intl.t('supplier-invoices.filters.status.value.rejected')
            }
        ];
    }
    get toReviewFilterOptions() {
        return [
            this.isAllInvoicesTab ? {
                code: TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE,
                value: this.intl.t('supplier-invoices.filters.to-review.value.due-and-overdue')
            } : false,
            {
                code: TO_REVIEW_FILTER_OPTIONS.DUPLICATES,
                value: this.intl.t('supplier-invoices.filters.to-review.value.duplicates')
            },
            {
                code: TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT,
                value: this.intl.t('supplier-invoices.filters.to-review.value.unrecognized')
            },
            {
                code: TO_REVIEW_FILTER_OPTIONS.MISSING_DATA,
                value: this.intl.t('supplier-invoices.filters.to-review.value.missing-data')
            },
            this.isAllInvoicesTab ? {
                code: TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS,
                value: this.intl.t('supplier-invoices.filters.to-review.value.no-matched-transactions')
            } : false
        ].filter(Boolean);
    }
    get selectedStatuses() {
        return this.multiStatusFilterOptions.filter((opt)=>this.args.status?.split(',').includes(opt.value));
    }
    get dueAndOverdueSelected() {
        return this.dueAndOverdueOptions.find(({ code })=>code === this.args.dueDate);
    }
    get dueAndOverdueOptions() {
        return [
            {
                code: DUE_DATE_FILTER.PAST_AND_TODAY,
                value: this.intl.t('supplier-invoices.imported.filter.option.due')
            },
            {
                code: DUE_DATE_FILTER.FUTURE,
                value: this.intl.t('supplier-invoices.imported.filter.option.upcoming')
            }
        ];
    }
    get multiStatusFilterPlaceholder() {
        let placeholder = this.intl.t('supplier-invoices.filters.status.placeholder');
        if (this.selectedStatuses.length > 0) {
            return `${placeholder} (${this.selectedStatuses.length})`;
        }
        return placeholder;
    }
    get toReviewSelected() {
        let { noMatchedTransactionsActive, documentType, hasMissingData, dueDate } = this.args;
        if (this.isAllInvoicesTab && noMatchedTransactionsActive) {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS);
        }
        if (documentType) {
            return this.toReviewFilterOptions.find(({ code })=>code === documentType);
        }
        if (hasMissingData) {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.MISSING_DATA);
        }
        if (this.isAllInvoicesTab && dueDate === 'past_and_today') {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE);
        }
    }
    trackFilter(filter_type, filter_value) {
        this.segment.track('supplier-invoices_filter_select', {
            tab: this.args.tab,
            filter_type,
            filter_value
        });
    }
    @action
    selectDueAndOverdue(selected) {
        this.args.resetSelectedItems();
        this.args.updateDueDate(selected?.code ?? null);
    }
    @action
    selectToReviewOption(selected) {
        this.trackFilter('to_review', selected?.code || '');
        this.args.resetSelectedItems();
        this.resetToReviewFilter();
        switch(selected?.code){
            case TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE:
                {
                    this.args.updateDueDate('past_and_today');
                    if (this.isAllInvoicesTab) {
                        this.args.updateStatus(INVOICE_STATUSES.toReview);
                    }
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.DUPLICATES:
            case TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT:
                {
                    this.args.updateDocumentType(selected.code);
                    if (this.isAllInvoicesTab) {
                        this.args.updateStatus(INVOICE_STATUSES.toReview);
                    }
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.MISSING_DATA:
                {
                    this.args.updateHasMissingData(true);
                    if (this.isAllInvoicesTab) {
                        this.args.updateStatus(INVOICE_STATUSES.toReview);
                    }
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS:
                {
                    this.args.toggleNoMatchedTransactions(true);
                    if (this.isAllInvoicesTab) {
                        this.args.updateStatus(INVOICE_STATUSES.paid);
                    }
                    break;
                }
        }
    }
    @action
    toggleAssignedToYou(value) {
        if (value) {
            this.trackFilter('assignee', 'assigned_to_you');
        }
        this.args.toggleAssignedToYou(value);
    }
    resetToReviewFilter() {
        this.args.toggleNoMatchedTransactions(false);
        this.args.updateDocumentType(null);
        this.args.updateHasMissingData(null);
        if (this.args.tab === TABS.ALL_INVOICES) {
            this.args.updateDueDate(null);
        }
    }
    @action
    updatePaymentDate(selected) {
        this.trackFilter('payment_date', selected?.code);
        this.args.updatePaymentDate(selected?.code ?? null);
    }
    @action
    selectStatus(selected) {
        this.trackFilter('status', selected?.code);
        this.args.updateStatus(selected?.code);
    }
    @action
    selectMultiStatus(selected) {
        this.trackFilter('status', selected?.map((s)=>s.value));
        this.args.resetSelectedItems();
        this.args.updateStatus(selected?.map((s)=>s.value).join(',') || undefined);
    }
    @action
    toggleNoMatchedTransactions(value) {
        this.trackFilter('not_reconciled', value ? 'active' : undefined);
        this.args.toggleNoMatchedTransactions(value);
        this.args.updateStatus(INVOICE_STATUSES.paid);
    }
    @action
    updateDocumentType(type) {
        if (this.args.documentType === type) {
            type = null;
        }
        this.args.updateDocumentType(type);
        if (INBOX_FILTERS_TRACKING_EVENTS[type]) {
            this.segment.track(INBOX_FILTERS_TRACKING_EVENTS[type]);
        }
    }
    @action
    updateHasMissingData() {
        let filterValue = !this.args.hasMissingData || null;
        this.args.updateHasMissingData(filterValue);
        this.segment.track(INBOX_FILTERS_TRACKING_EVENTS[TO_REVIEW_FILTER_OPTIONS.MISSING_DATA]);
    }
    get inboxDuplicatesFilterLabel() {
        return `${this.intl.t('supplier-invoices.filters.to-review.value.duplicates')} (${this.insights?.toReviewDuplicatesCounter ?? 0})`;
    }
    get inboxUnrecognizedFilterLabel() {
        return `${this.intl.t('supplier-invoices.filters.to-review.value.unrecognized')} (${this.insights?.toReviewUnrecognizedCounter ?? 0})`;
    }
    get inboxMissingDataFilterLabel() {
        return `${this.intl.t('supplier-invoices.filters.to-review.value.missing-data')} (${this.insights?.toReviewMissingDataCounter ?? 0})`;
    }
    get showToggleFilters() {
        return [
            FILTERS.DUPLICATES,
            FILTERS.NON_FINANCIAL_DOCUMENT,
            FILTERS.MISSING_DATA
        ].some((filter)=>this.filters.includes(filter));
    }
    static{
        template_990608dc25db483e81ba540389264c9f(`
    <div class={{styles.filters-wrapper}}>
      {{#each this.filters as |filter|}}
        {{#if (and (eq filter FILTERS.SUPPLIERS) this.showSuppliersFilter)}}
          <span data-test-filter={{filter}}>
            <SuppliersFilter
              @selected={{@selectedSupplierName}}
              @options={{@suppliers}}
              @onChange={{@selectSupplier}}
              @search={{@handleSupplierSearch}}
            />
          </span>
        {{/if}}
        {{#if (eq filter FILTERS.ARCHIVED_PAID_STATUS)}}
          <span data-test-filter={{filter}}>
            <FilterSelect
              @placeholder={{t 'supplier-invoices.completed.filter.label'}}
              @value={{this.selectedStatus}}
              @options={{this.completedStatusOptions}}
              @update={{this.selectStatus}}
              data-test-supplier-invoice-status-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          </span>
        {{/if}}
        {{#if (eq filter FILTERS.PAYMENT_DATE)}}
          <span data-test-filter={{filter}}>
            <FilterSelect
              @placeholder={{t 'supplier-invoices.filters.payment-date.placeholder'}}
              @value={{this.selectedPaymentDate}}
              @options={{this.paymentDateFilterOptions}}
              @update={{this.updatePaymentDate}}
              data-test-supplier-invoice-payment-date-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          </span>
        {{/if}}
        {{#if (eq filter FILTERS.MULTI_STATUS)}}
          <span class={{styles.filter-wrapper}} data-test-filter={{filter}}>
            <ReactBridgeWithProviders
              @reactComponent={{MultiSelectFilter}}
              @props={{hash
                selected=this.selectedStatuses
                options=this.multiStatusFilterOptions
                placeholder=this.multiStatusFilterPlaceholder
                onSubmit=this.selectMultiStatus
              }}
            />
          </span>
        {{/if}}
        {{#if (and (eq filter FILTERS.NO_MATCHED_TRANSACTIONS) this.canUpdateSupplierInvoices)}}
          <span data-test-filter={{filter}}>
            <ReactBridge
              @reactComponent={{ToggleFilter}}
              @props={{hash
                isSelected=@noMatchedTransactionsActive
                onClick=this.toggleNoMatchedTransactions
              }}
            >
              {{t 'supplier-invoices.filters.matched-transactions.placeholder'}}
            </ReactBridge>
          </span>
        {{/if}}
        {{#if (eq filter FILTERS.ASSIGNED_TO_YOU)}}
          <span data-test-filter={{filter}}>
            <ReactBridge
              @reactComponent={{ToggleFilter}}
              @props={{hash isSelected=@assignedToYouActive onClick=this.toggleAssignedToYou}}
            >
              {{t 'supplier-invoices.filters.assigned.placeholder'}}
            </ReactBridge>
          </span>
        {{/if}}
        {{#if (and (eq filter FILTERS.TO_REVIEW) this.canUpdateSupplierInvoices)}}
          <span data-test-filter={{filter}}>
            <FilterSelect
              @placeholder={{t 'supplier-invoices.filters.to-review.placeholder'}}
              @value={{this.toReviewSelected}}
              @options={{this.toReviewFilterOptions}}
              @update={{this.selectToReviewOption}}
              data-test-supplier-invoice-to-review-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          </span>
        {{/if}}
        {{#if (eq filter FILTERS.DUE_DATE)}}
          <span data-test-filter={{filter}}>
            <FilterSelect
              @placeholder={{t 'supplier-invoices.imported.filter.label'}}
              @value={{this.dueAndOverdueSelected}}
              @options={{this.dueAndOverdueOptions}}
              @update={{this.selectDueAndOverdue}}
              data-test-supplier-invoice-due-date-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          </span>
        {{/if}}
      {{/each}}
      {{#if this.showToggleFilters}}
        <div class={{styles.toggle-filter-wrapper}}>
          {{#each this.filters as |filter|}}
            {{#if (eq filter FILTERS.DUPLICATES)}}
              <span data-test-filter={{filter}}>
                <ReactBridge
                  @reactComponent={{ToggleFilter}}
                  @props={{hash
                    isSelected=(eq @documentType TO_REVIEW_FILTER_OPTIONS.DUPLICATES)
                    onClick=(fn this.updateDocumentType TO_REVIEW_FILTER_OPTIONS.DUPLICATES)
                  }}
                  data-test-supplier-invoice-duplicates-filter
                >
                  {{this.inboxDuplicatesFilterLabel}}
                </ReactBridge>
              </span>
            {{/if}}
            {{#if (eq filter FILTERS.NON_FINANCIAL_DOCUMENT)}}
              <span data-test-filter={{filter}}>
                <ReactBridge
                  @reactComponent={{ToggleFilter}}
                  @props={{hash
                    isSelected=(eq @documentType TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT)
                    onClick=(fn
                      this.updateDocumentType TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT
                    )
                  }}
                  data-test-supplier-invoice-unrecognized-filter
                >
                  {{this.inboxUnrecognizedFilterLabel}}
                </ReactBridge>
              </span>
            {{/if}}
            {{#if (eq filter FILTERS.MISSING_DATA)}}
              <span data-test-filter={{filter}}>
                <ReactBridge
                  @reactComponent={{ToggleFilter}}
                  @props={{hash isSelected=@hasMissingData onClick=this.updateHasMissingData}}
                  data-test-supplier-invoice-missing-data-filter
                >
                  {{this.inboxMissingDataFilterLabel}}
                </ReactBridge>
              </span>
            {{/if}}
          {{/each}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
