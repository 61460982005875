export default {
  "row": "qQN",
  "greyed-out": "qQv",
  "supplier-name": "qQo",
  "cell": "qQn",
  "supplier": "qQi",
  "email-address": "qQA",
  "outstanding-balance": "qQY",
  "quick-actions": "qQh",
  "empty": "qQf",
  "supplier-container": "qQK",
  "grey-background": "qQG"
};
