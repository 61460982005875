export default {
  "button-wrapper": "qIv",
  "btn-filter": "qIo",
  "name-wrapper": "qIn",
  "preset-name": "qIi",
  "cancel-placeholder": "qIA",
  "cancel-button": "qIY",
  "dropdown-opened": "qIh",
  "dropdown-closed": "qIf",
  "dropdown-menu": "qIK",
  "loading-item": "qIG",
  "placeholder": "qIr",
  "label": "qIb",
  "list-body": "qIy",
  "list-item": "qIU",
  "error-state": "qIj body-2",
  "warning": "qIW",
  "warning-icon": "qIu",
  "footer": "qtS",
  "add-icon": "qtc",
  "empty-state": "qtq"
};
