export default {
  "card-header": "qMd",
  "counterparty-name": "qMI",
  "header-section": "qMt",
  "pan-section": "qMM header-section body-2",
  "infobox": "qMP header-section",
  "see-card-button": "qMl",
  "card-identifier-section": "qMX",
  "card-icon": "qMC",
  "card-status": "qMk",
  "nickname": "qMJ body-1",
  "pan": "qMs body-2"
};
