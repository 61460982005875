export default {
  "sidebar-content": "qly",
  "section": "qlU",
  "header-wrapper": "qlj",
  "transfer-id": "qlW caption",
  "small-padding": "qlu",
  "title": "qXS",
  "subtitle": "qXc",
  "actions": "qXq"
};
