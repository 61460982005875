export default {
  "modal": "qZT",
  "action-container": "qZz",
  "similar-label": "qZa",
  "info-icon": "qZH",
  "container": "qZO",
  "subtitle": "qZm caption-bold",
  "error": "qZp",
  "item-container": "qZx"
};
