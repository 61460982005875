export default {
  "row": "qBB",
  "active": "qBE",
  "dot": "qBd",
  "red": "qBI",
  "green": "qBt",
  "yellow": "qBM",
  "align-right": "qBP",
  "animated": "qBl",
  "fadein-item": "qBX",
  "cell": "qBC body-2",
  "cell-content": "qBk",
  "amount": "qBJ body-1",
  "subtitle": "qBs caption",
  "status": "qBF",
  "cell-amount": "qBg"
};
