export default {
  "header-cell": "qPa",
  "header-content": "qPH caption-bold",
  "active": "qPO",
  "align-right": "qPm",
  "empty": "qPp",
  "align-checkbox": "qPx",
  "col-5": "qPw",
  "col-9": "qPN",
  "emitted-at-header-tag": "qPv"
};
