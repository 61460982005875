/* import __COLOCATED_TEMPLATE__ from './supplier-invoice-details.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';

interface SelfBillingFormSupplierInvoiceDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class SelfBillingFormSupplierInvoiceDetailsComponent extends Component<SelfBillingFormSupplierInvoiceDetailsSignature> {
  // @ts-expect-error
  @tracked issueDate = this.args.invoice.issueDate;
  numberMaxLength = 20;

  datePicker = DatePicker;

  get issueDateValue() {
    return this.issueDate ? parseDate(this.issueDate) : null;
  }

  @action
  // @ts-expect-error
  updateSupplierInvoiceNumber(number) {
    // @ts-expect-error
    this.args.invoice.set('supplierInvoiceSnapshot', {
      // @ts-expect-error
      ...(this.args.invoice.get('supplierInvoiceSnapshot') ?? {}),
      number,
    });
  }

  @action
  // @ts-expect-error
  updateSupplierInvoiceIssueDate(date) {
    this.issueDate = date?.toString();
    // @ts-expect-error
    this.args.invoice.set('supplierInvoiceSnapshot', {
      // @ts-expect-error
      ...(this.args.invoice.get('supplierInvoiceSnapshot') ?? {}),
      issueDate: date.toString() || undefined,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::Form::SupplierInvoiceDetails': typeof SelfBillingFormSupplierInvoiceDetailsComponent;
  }
}
