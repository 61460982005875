export default {
  "header-cell": "qQL",
  "first-col": "qQT",
  "mid-col": "qQz",
  "outstanding-col": "qQa",
  "header-content": "qQH caption-bold",
  "active": "qQO",
  "quick-actions": "qQm",
  "empty": "qQp"
};
