export default {
  "card": "qFd",
  "card-primary": "qFI",
  "content": "qFt",
  "caption-text": "qFM",
  "content-primary": "qFP",
  "disabled": "qFl",
  "illustration": "qFX",
  "illustration-primary": "qFC",
  "illustration-disabled": "qFk",
  "cta": "qFJ",
  "dismiss-button": "qFs",
  "buttons": "qFF",
  "required-badge": "qFg"
};
