export default {
  "invoice-sidebar": "cjv",
  "body": "cjo",
  "box": "cjn",
  "box-header": "cji",
  "sidebar-box": "cjA",
  "border-top": "cjY",
  "row": "cjh",
  "greyed-out": "cjf",
  "strike-through": "cjK"
};
