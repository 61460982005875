export default {
  "container": "qtR",
  "item": "qtQ caption-bold",
  "hover": "qte",
  "active": "qtB",
  "name-wrapper": "qtE",
  "name": "qtd body-2",
  "count": "qtI",
  "actions-placeholder": "qtt",
  "actions": "qtM",
  "show": "qtP",
  "action-button": "qtl"
};
