export default {
  "vat-lines": "qFK",
  "vat-line": "qFG",
  "vat-input": "qFr",
  "vat-rate": "qFb",
  "vat-option-button": "qFy",
  "disabled": "qFU",
  "vat-option-tooltip": "qFj",
  "remove-label": "qFW",
  "remove-button": "qFu",
  "vat-total": "qgS",
  "vat-total-amount": "qgc",
  "add-vat-line": "qgq",
  "with-vat-lines": "qgZ"
};
