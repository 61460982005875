export default {
  "row": "qlP",
  "col": "qll",
  "header": "qlX",
  "block": "qlC",
  "col-5": "qlk",
  "col-6": "qlJ",
  "col-9": "qls",
  "col-12": "qlF",
  "hide": "qlg"
};
