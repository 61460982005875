export default {
  "sidebar-header": "qMF",
  "sidebar-header__top": "qMg",
  "sidebar-header__body": "qMD",
  "sidebar-header__content": "qMV",
  "sidebar-header__date": "qML",
  "infobox": "qMT",
  "infobox--link": "qMz",
  "ghost": "qMa",
  "fail": "qMH",
  "r-transaction-disclaimer": "qMO",
  "hold-disclaimer": "qMm",
  "transaction-link": "qMp body-2",
  "icon-link": "qMx"
};
