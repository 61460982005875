export default {
  "container": "qsN",
  "container-column": "qsv",
  "content-wrapper": "qso",
  "media-wrapper": "qsn",
  "content": "qsi",
  "title": "qsA",
  "subtitle": "qsY",
  "content-slot": "qsh",
  "sticky-panel": "qsf",
  "sticky-actions": "qsK"
};
