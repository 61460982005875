export default {
  "container": "qFH",
  "cards-container": "qFO",
  "card": "qFm",
  "expanded": "qFp",
  "hidden": "qFx",
  "collapsed": "qFw",
  "with-expand-collapse-animation": "qFN",
  "with-dismiss-animation": "qFv",
  "has-one-card": "qFo",
  "dismissing": "qFn",
  "with-hover-animation": "qFi",
  "toggle-button": "qFA",
  "toggle-container": "qFY",
  "toggle-button-icon": "qFh",
  "has-two-cards": "qFf"
};
