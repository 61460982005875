export default {
  "item": "qZw",
  "checkbox": "qZN",
  "details": "qZv",
  "flex": "qZo",
  "header": "qZn qZo",
  "flex-no-gap": "qZi qZo",
  "file-name": "qZA",
  "label": "qZY body-2",
  "self-icon": "qZh",
  "einvoice-icon": "qZf",
  "type-value": "qZK",
  "with-separator": "qZG",
  "dimmed": "qZr"
};
