export default {
  "fileupload": "qsj",
  "file": "qsW",
  "label": "qsu",
  "zone": "qFS",
  "error": "qFc",
  "hide": "qFq",
  "dropzone": "qFZ",
  "hidden": "qFR",
  "upload-icon": "qFQ",
  "dropping": "qFe",
  "bounce2": "qFB",
  "error-message": "qFE"
};
