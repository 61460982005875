export default {
  "sidepanel": "qQq",
  "close-icon": "qQZ",
  "close-button": "qQR",
  "supplier-card": "qQQ",
  "supplier-card-details": "qQe",
  "supplier-name": "qQB title-3 mb-8",
  "supplier-card-placeholder": "qQE",
  "box": "qQd",
  "box-element": "qQI",
  "related-invoices-title": "qQt",
  "placeholder-container": "qQM",
  "related-invoices-wrapper": "qQP",
  "related-invoice-placeholder": "qQl",
  "related-invoice-placeholder-inner": "qQX",
  "actions": "qQC",
  "currency": "qQk"
};
