export default {
  "insight-pill": "qqj",
  "insight-pill-figure": "qqW",
  "insight-pill-renderer": "qqu",
  "loading": "qZS",
  "counter": "qZc",
  "error": "qZq",
  "insight-pill-label": "qZZ",
  "insight-pill-subtitle": "qZR caption",
  "hidden": "qZQ",
  "missing-amounts-warning": "qZe",
  "missing-amounts": "qZB"
};
