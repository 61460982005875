export default {
  "attachment-sidebar": "qSK",
  "sidebar-scroll": "qSG",
  "top-section": "qSr",
  "timeline-section": "qSb",
  "sidebar-header": "qSy",
  "matched-section": "qSU",
  "collapsible-timeline": "qSj",
  "timeline-status-label": "qSW",
  "m-24": "qSu"
};
