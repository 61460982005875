export default {
  "savings-account": "cyg",
  "closed": "cyD",
  "badge": "cyV",
  "subtitle": "cyL",
  "amount": "cyT",
  "processing": "cyz",
  "gain": "cya",
  "active": "cyH",
  "progress": "cyO",
  "progress-bar": "cym",
  "progress-text": "cyp",
  "info": "cyx",
  "menu-item": "cyw body-2",
  "close-account": "cyN"
};
