export default {
  "bank-account-option": "qBD",
  "row": "qBV",
  "active": "qBL",
  "animated": "qBT",
  "fadein-item": "qBz",
  "cell": "qBa body-2",
  "cell-content": "qBH",
  "subtitle": "qBO caption",
  "no-padding": "qBm",
  "empty": "qBp",
  "note": "qBx",
  "cell-content-amount": "qBw body-1",
  "cell-content-receipt": "qBN body-1",
  "cell-quick-actions": "qBv",
  "account-selector": "qBo",
  "cell-type": "qBn"
};
