export default {
  "transfer-header": "qPI",
  "infobox": "qPt header-section",
  "proof-section": "qPM",
  "proof-link": "qPP",
  "download-icon": "qPl",
  "cancel-button": "qPX",
  "request-refund-button": "qPC",
  "refund-requested-indicator": "qPk",
  "recall-indicator": "qPJ",
  "payment-purpose": "qPs",
  "payment-purpose-label": "qPF",
  "repeat-infobox": "qPg qPt header-section",
  "additional-infobox": "qPD qPt header-section",
  "repeat-transfer-action": "qPV",
  "financing-not-compliant-disclaimer": "qPL"
};
