export default {
  "display-block": "qsE",
  "pay-later-tooltip": "qsd",
  "disabled": "qsI",
  "stepper": "qst",
  "installments": "qsM",
  "flex-items": "qsP",
  "day-label": "qsl body-2",
  "installment-amount": "qsX body-1",
  "spacing": "qsC",
  "info-icon": "qsk",
  "detail": "qsJ",
  "detail-value": "qss",
  "extra": "qsF",
  "extra-value": "qsg",
  "badge": "qsD",
  "title": "qsV",
  "link": "qsL"
};
