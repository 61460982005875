/* import __COLOCATED_TEMPLATE__ from './create-invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ToggleButton } from '@repo/design-system-kit';
import { PdfPreview } from '@repo/domain-kit/invoices';
import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

export const SWITCH_VALUES = {
  SUPPLIER_INVOICE: 'SUPPLIER_INVOICE',
  SELF_INVOICE: 'SELF_INVOICE',
};

interface SelfBillingCreateInvoiceModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SelfBillingCreateInvoiceModalComponent extends Component<SelfBillingCreateInvoiceModalSignature> {
  toggleButton = ToggleButton;
  pdfPreviewComponent = PdfPreview;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare localeManager: Services['localeManager'];

  @tracked activeSwitchItem = SWITCH_VALUES.SUPPLIER_INVOICE;

  // @ts-expect-error
  @reads('args.data.selfInvoice') invoice;
  // @ts-expect-error
  @reads('args.data.selfInvoice.supplierSnapshot') supplier;
  // @ts-expect-error
  @reads('args.data.selfInvoice.supplierInvoiceSnapshot') supplierInvoice;

  SWITCH_VALUES = SWITCH_VALUES;
  switchItems = [
    {
      value: SWITCH_VALUES.SELF_INVOICE,
      // @ts-expect-error
      label: this.intl.t('self-billing.create.toggle.self-invoice'),
    },
    {
      value: SWITCH_VALUES.SUPPLIER_INVOICE,
      // @ts-expect-error
      label: this.intl.t('self-billing.create.toggle.supplier-invoice'),
    },
  ];

  get hideSelfInvoicePreview() {
    return this.activeSwitchItem === this.SWITCH_VALUES.SUPPLIER_INVOICE;
  }

  get documentVariant() {
    return this.organizationManager.organization.legalCountry;
  }

  // Ember - React compatibility getter, forces Ember to create a new ref for the prop on mutation updates
  get document() {
    // @ts-expect-error
    let items = this.invoice.items?.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      totalExcludingVat: item.totalExcludingVat,
      discount: item.discount,
      totalDiscount: item.totalDiscount,
      links: item.links,
      subTotal: item.subTotal,
      totalAmount: item.totalAmount,
      totalVat: item.totalVat,
      vatExemptionCode: item.vatExemptionCode,
    }));

    return {
      number: this.invoice.number,
      issueDate: this.invoice.issueDate,
      documentType: this.invoice.documentType,
      items,
      discount: this.invoice.discount,
      totalExcludingVat: this.invoice.totalExcludingVat,
      totalDiscount: this.invoice.totalDiscount,
      discountedTotalExcludingVat: this.invoice.discountedTotalExcludingVat,
      welfareFundAmount: this.invoice.welfareFundAmount,
      displayEachVatSubtotals: this.invoice.displayEachVatSubtotals,
      vatSubtotals: this.invoice.vatSubtotals,
      totalVat: this.invoice.totalVat,
      withholdingTax: this.invoice.withholdingTax,
      withholdingTaxAmount: this.invoice.withholdingTaxAmount,
      stampDutyAmount: this.invoice.stampDutyAmount,
      totalAmount: this.invoice.totalAmount,
      depositAmount: this.invoice.depositAmount,
      totalAmountDue: this.invoice.totalAmountDue,
      currency: this.invoice.currency,
      locale: this.invoice.isNew ? this.localeManager.locale : this.invoice.locale,
    };
  }

  get merchant() {
    return this.supplier
      ? {
          legalName: this.supplier.name,
          address: {
            streetAddress: this.supplier.address,
            zipCode: this.supplier.zipCode,
            city: this.supplier.city,
            countryCode: this.supplier.countryCode,
          },
          vatNumber: this.supplier.vatNumber,
        }
      : undefined;
  }

  get client() {
    const address = this.organizationManager.organization.address;
    const organizationStreetAddress = address.firstLine
      ? address.firstLine
          .concat(address.secondLine ? ` ${address.secondLine}` : '')
          .concat(address.thirdLine ? ` ${address.thirdLine}` : '')
      : '';

    return {
      name: this.organizationManager.organization.legalName,
      billingAddress: {
        streetAddress: organizationStreetAddress,
        zipCode: address.zipcode,
        city: address.city,
        countryCode: address.country,
      },
      email: this.organizationManager.organization.contactEmail,
      tinNumber: this.organizationManager.organization.legalNumber,
      locale: this.organizationManager.organization.locale,
    };
  }

  get relatedInvoice() {
    return {
      number: this.supplierInvoice.number,
      issueDate: this.supplierInvoice.issueDate,
    };
  }

  // @ts-expect-error
  @action togglePreview(state) {
    this.activeSwitchItem = state;
    this.segment.track('invoice_preview_switch');
  }

  confirmExitTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('self-billing.create.exit-modal.title'),
      description: this.intl.t('self-billing.create.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.exitTask,
    });
  });

  exitTask = task(async close => {
    await close();
    this.router.transitionTo('supplier-invoices.index');
    this.segment.track('self-invoice_creation_canceled');
  });

  confirmInvoiceCreationTask = dropTask(async () => {
    this.segment.track('self-invoice_creation_submitted');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('self-billing.create.confirm-modal.title'),
      description: this.intl.t('self-billing.create.confirm-modal.description', { htmlSafe: true }),
      confirm: this.intl.t('self-billing.create.confirm-modal.create-invoice'),
      cancel: this.intl.t('btn.cancel'),
      confirmTask: this.saveInvoiceTask,
    });
  });

  saveInvoiceTask = dropTask(async close => {
    try {
      // @ts-expect-error
      await this.args.data.selfInvoice.save();
      this.segment.track('self-invoice_creation_confirmed');
      // @ts-expect-error
      this.args.data.supplierInvoice.selfInvoiceId = true;
      // @ts-expect-error
      this.args.data.selfInvoice.clearItemsWithNoId();
      this.router.transitionTo('supplier-invoices.index');
      this.toastFlashMessages.toastSuccess(this.intl.t('self-billing.toast.creation-success'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('self-billing.toast.creation-fail'));
    }
    await close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::CreateInvoiceModal': typeof SelfBillingCreateInvoiceModalComponent;
  }
}
