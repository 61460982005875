export default {
  "container": "qtm",
  "header": "qtp",
  "title": "qtx caption-bold",
  "search-icon": "qtw",
  "text": "qtN body-2",
  "trigger": "qtv",
  "loading-state": "qto",
  "dropzone-placeholder-wrapper": "qtn",
  "dropzone-placeholder": "qti",
  "file-loading": "qtA",
  "search-qonto-cta": "qtY",
  "search-qonto-cta-content": "qth",
  "tooltip-wrapper": "qtf",
  "invoices-tooltip": "qtK"
};
