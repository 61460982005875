export default {
  "insight-pill": "qqi",
  "interactive": "qqA",
  "focus": "qqY",
  "subtitle": "qqh caption",
  "hidden": "qqf",
  "missing-amounts-warning": "qqK",
  "totals": "qqG",
  "counter": "qqr title-3",
  "loading": "qqb",
  "error": "qqy",
  "title": "qqU title-4"
};
