export default {
  "row": "qJe body-1",
  "active": "qJB",
  "cell": "qJE",
  "empty": "qJd",
  "ellipsis": "qJI",
  "text-secondary": "qJt",
  "cell-type": "qJM",
  "cell-reason": "qJP",
  "cell-amount": "qJl",
  "row-sidebar": "qJX",
  "animated": "qJC",
  "fadein-item": "qJk",
  "animated-cell": "qJJ"
};
