export default {
  "modal": "qEK",
  "section": "qEG",
  "header": "qEr",
  "title": "qEb",
  "separator-title": "qEy",
  "icon": "qEU",
  "formats": "qEj",
  "columns": "qEW",
  "column-list": "qEu",
  "draggable-column": "qdS",
  "column-field": "qdc",
  "column-selector": "qdq",
  "column-with-error": "qdZ",
  "handle": "qdR",
  "label-color": "qdQ",
  "disclaimer": "qde"
};
