export default {
  "progress-container": "qEE",
  "line-container": "qEd",
  "dashed-line": "qEI",
  "solid-line": "qEt",
  "circle-container": "qEM",
  "circle": "qEP",
  "current-step-dot": "qEl",
  "success-icon": "qEX",
  "rejected-icon": "qEC"
};
