export default {
  "transfer-form": "qXK",
  "transfer-form-title": "qXG",
  "transfer-form-subtitle": "qXr",
  "form": "qXb",
  "form-footer": "qXy",
  "footer-btn": "qXU",
  "header": "qXj",
  "main-settings": "qXW",
  "additional-settings": "qXu",
  "form-container": "qCS"
};
