export default {
  "form": "qcf",
  "form-title": "qcK title2",
  "row": "qcG",
  "col": "qcr",
  "actions": "qcb",
  "buttons": "qcy",
  "collapsible-timeline": "qcU",
  "link": "qcj",
  "timeline-status-label": "qcW",
  "section": "qcu",
  "einvoicing-disclaimer": "qqS",
  "disclaimer-section": "qqc qcu",
  "related-documents-card-margin-top": "qqq",
  "my-24": "qqZ"
};
