export default {
  "wrapper": "cUw",
  "content": "cUN",
  "title": "cUv",
  "aside": "cUo",
  "description": "cUn",
  "faq-link": "cUi",
  "close-btn": "cUA",
  "container": "cUY",
  "mandate-field-list": "cUh",
  "mandate-field-item": "cUf",
  "mandate-field-item__dot": "cUK",
  "mandate-field-item__text": "cUG"
};
