export default {
  "details": "qcS",
  "details-header": "qcc",
  "details-header-amount": "qcq",
  "details-content": "qcZ",
  "details-footer": "qcR",
  "invoice-status-container": "qcQ body-2",
  "invoice-dates": "qce",
  "overdue": "qcB",
  "details-list": "qcE",
  "details-list-item": "qcd",
  "details-list-item-label": "qcI body-2",
  "details-list-item-value": "qct",
  "border-top": "qcM",
  "description": "qcP",
  "description-value": "qcl",
  "edit-button": "qcX body-2",
  "edit-icon": "qcC",
  "badge-union": "qck",
  "edit-description": "qcJ",
  "amount-disclaimer": "qcs",
  "strike-amount": "qcF"
};
