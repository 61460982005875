export default {
  "wrapper": "qEH",
  "section-wrapper": "qEO",
  "header": "qEm",
  "title": "qEp body-1",
  "close-icon": "qEx",
  "section": "qEw",
  "section-title": "qEN caption-bold",
  "labels": "qEv",
  "labels-title": "qEo",
  "section-checkbox": "qEn",
  "grow": "qEi scroll",
  "label": "qEA",
  "buttons": "qEY"
};
