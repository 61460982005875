export default {
  "row": "qkt body-1",
  "active": "qkM",
  "cell": "qkP",
  "empty": "qkl",
  "ellipsis": "qkX",
  "text-secondary": "qkC",
  "transfer-type": "qkk",
  "transfer-type__icon": "qkJ",
  "quick-actions": "qks",
  "amount": "qkF",
  "cell-approve": "qkg",
  "cell-type": "qkD",
  "cell-requester": "qkV",
  "account-select": "qkL",
  "row-compact": "qkT",
  "row-sidebar": "qkz",
  "cell-amount": "qka",
  "hidden": "qkH",
  "animated": "qkO",
  "fadein-item": "qkm",
  "animated-cell": "qkp"
};
