export default {
  "modal": "cUU",
  "container": "cUj",
  "form-container": "cUW",
  "preview-container": "cUu",
  "preview-content": "cjS",
  "switch-container": "cjc",
  "supplier-invoice-preview": "cjq",
  "header": "cjZ",
  "footer": "cjR",
  "form-content": "cjQ",
  "form-content-section": "cje",
  "form-disclaimer": "cjB",
  "hidden": "cjE"
};
