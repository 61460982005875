export default {
  "row": "qBi",
  "icon": "qBA",
  "cell": "qBY",
  "cell-date": "qBh",
  "cell-amount": "qBf",
  "cell-status": "qBK",
  "cell-content": "qBG",
  "align-right": "qBr"
};
