export default {
  "wrapper": "qXZ",
  "ember-power-select-dropdown": "qXR",
  "status": "qXQ body-1",
  "error": "qXe",
  "success": "qXB",
  "type-container": "qXE",
  "type-close": "qXd",
  "date": "qXI",
  "close-icon": "qXt",
  "container": "qXM",
  "container-picto": "qXP",
  "container-picto-status": "qXl",
  "general": "qXX",
  "general-amount": "qXC",
  "unprocessed": "qXk",
  "fx-rate": "qXJ",
  "general-counterparty": "qXs body-2",
  "infobox": "qXF",
  "account-infobox": "qXg qXF",
  "initiator-infobox": "qXD qXF",
  "transaction-infobox": "qXV qXF",
  "repeat-infobox": "qXL qXF",
  "item": "qXT body-2",
  "eye-icon": "qXz",
  "repeat-transfer-action": "qXa",
  "disclaimer": "qXH",
  "start-verification-link": "qXO",
  "financing-not-compliant-disclaimer": "qXm"
};
