export default {
  "row": "qPo",
  "item-bulk": "qPn",
  "active": "qPi",
  "disabled": "qPA",
  "cell": "qPY body-2",
  "not-disabled": "qPh",
  "item-amount-disabled": "qPf",
  "item-amount-credit": "qPK",
  "no-padding": "qPG",
  "cell-content": "qPr",
  "centered-cell": "qPb",
  "item-counterparty-icon": "qPy mr-16",
  "avatar": "qPU",
  "item-counterparty-name": "qPj",
  "item-counterparty-name-status": "qPW",
  "disputing-chargeback": "qPu",
  "item-counterparty-name-text": "qlS",
  "item-amount": "qlc body-1",
  "item-amount-foreign": "qlq",
  "attachment-icon": "qlZ",
  "item-method": "qlR",
  "bank-account-avatar": "qlQ",
  "attachment-status-dot": "qle",
  "attached": "qlB",
  "missing": "qlE",
  "item-attachment": "qld",
  "compact": "qlI",
  "upsell-badge": "qlt",
  "fadein-item": "qlM"
};
