export default {
  "review": "cUR",
  "review-content": "cUQ",
  "review-parameters": "cUe",
  "row": "cUB",
  "row__border": "cUE",
  "label": "cUd",
  "value": "cUI",
  "error": "cUt",
  "tooltip-icon": "cUM",
  "terms-link": "cUP"
};
